import { NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';

type SharedNotificationSupportedTypes = 'error' | 'success' | 'warning' | 'info';

export interface SharedNotificationConfig {
  msg: string;
  status: 'error' | 'success';
  iconMeta: {
    icon: string;
    position: 'before' | 'after';
  };
}

/**
 * This is a shared component which will receive input and then render the
 * appropriate notification, which can be success, error, warning or any other
 * which we may come up with.
 */
@Component({
  selector: 'app-shared-notification-component',
  styleUrls: ['shared.notification.component.scss'],
  templateUrl: 'shared.notification.component.html',
  standalone: true,
  imports: [NgTemplateOutlet, NgIf],
})
export class SharedNotificationComponent {
  @Input() config: SharedNotificationConfig;

  @ViewChild('successResponseTemplate', { static: true })
  private _successResponseTemplate: TemplateRef<any>;

  @ViewChild('errorResponseTemplate', { static: true })
  private _errorResponseTemplate: TemplateRef<any>;

  public renderNotificationTemplate(
    notificationType: SharedNotificationSupportedTypes,
  ): TemplateRef<any> {
    return notificationType === 'success'
      ? this._successResponseTemplate
      : this._errorResponseTemplate;
  }
}
