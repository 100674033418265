/* eslint-disable radix */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
// Ignoring the linting check because this comes from a Kotlin Library
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
// @ts-ignore
import { getSupportedPhoneCountriesUseCase } from '@taager-experience-shared/country-resolver';
import { Subject, takeUntil } from 'rxjs';
import { Country } from '../../interfaces/countries';

/**
 * This component will be more than just a phone number input field. Why is that? Well,
 * we need a way to re-use the now seemingly common phone number structure which we will
 * be following i.e [code][phonenumber].
 *
 * So, instead of duplicating this logic, best to isolate it in one shareable component
 * and simple use it as needed. Why is that? Well, here we can also add onto it the
 * field-validation-indicator component as well, so we ONLY ever need to change this
 * component and not multiple places in the code.
 */

export interface MetaPhoneNumberOutput {
  phoneNumber: string;
  country: Country;
}

@Component({
  selector: 'app-meta-phone-number-field',
  styleUrls: ['meta-phone-number-field.component.scss'],
  templateUrl: 'meta-phone-number-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    MatMenuModule,
    NgIf,
    NgTemplateOutlet,
    MatIconModule,
    NgFor,
  ],
})
export class MetaPhoneNumberFieldComponent implements OnInit, OnChanges {
  /**
   * If no country code is provided, by default we assume that it is Egypt.
   */
  @Input() userCountryCode = 'EGY';

  @Input() userPhoneNumber?: string;

  @Output() result$: EventEmitter<MetaPhoneNumberOutput> =
    new EventEmitter<MetaPhoneNumberOutput>();

  @Input() showValidationErrorIndicator = false;

  @Input() fieldIsValid?: boolean;

  @Input() prefixDisabled = false;

  @Input() removeUncoveredCountries = false;

  @ViewChild('countriesListMenuTrigger', { static: true })
  private _countriesListMenuTrigger: MatMenuTrigger;

  @ViewChild('phoneNumberWrapperElement', { static: true })
  private _phoneNumberWrapperElement: ElementRef<HTMLFormElement>;

  public userCountry: Country;

  public metaPhoneNumberFormGroup: UntypedFormGroup;

  public countryMenuIsOpened: boolean;

  public availableCountries: Array<Country>;

  public visibleCountries: Array<Country>;

  public countryPhoneHint: string;

  private _onDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _renderer2: Renderer2) {}

  ngOnInit(): void {
    this._initializeMetaPhoneNumberFormGroup();
    this._doGetUserCountry();
    this._listenForMenuOpenClosedStates();
    this._getCountriesList();
    this._getPhoneHint();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.fieldIsValid?.firstChange) {
      this.fieldIsValid = changes?.fieldIsValid?.currentValue;
      this._resolveStyleToAppyToElement();
    }
  }

  public onCountryChange(country: Country): void {
    this.userCountryCode = country.isoCode3;
    this.userCountry = country;
    this._getPhoneHint();
    this._doSetVisibleCountriesList();
    this._commonPatchFormValue('country', this.userCountry, true);
    this._doSetPhoneNumberValidators();
  }

  public onInputBlurEvent(): void {
    this._resolveStyleToAppyToElement();
  }

  private _resolveStyleToAppyToElement(): void {
    if (this.showValidationErrorIndicator) {
      if (!this.fieldIsValid) {
        this._doAppyStyleToElement(
          this._phoneNumberWrapperElement.nativeElement,
          'border',
          '1px solid #ce0021',
        );
      } else {
        this._doAppyStyleToElement(
          this._phoneNumberWrapperElement.nativeElement,
          'border',
          '1px solid #D9DBDF',
        );
      }
    }
  }

  private _doAppyStyleToElement(element: HTMLElement, styleName: string, styleValue: string): void {
    this._renderer2.setStyle(element, styleName, styleValue);
  }

  private _getCountriesList(): void {
    this.availableCountries = getSupportedPhoneCountriesUseCase(this.removeUncoveredCountries);
    this._doSetVisibleCountriesList();
  }

  private _getPhoneHint(): void {
    this.countryPhoneHint = getSupportedPhoneCountriesUseCase(this.removeUncoveredCountries).find(
      (country: any) => country.isoCode3 === this.userCountryCode,
    )?.phoneNumberHint;
  }

  private _doSetVisibleCountriesList(): void {
    this.visibleCountries = this.availableCountries.filter(
      (country) => country.isoCode3 !== this.userCountryCode,
    );
  }

  private _listenForMenuOpenClosedStates(): void {
    this._countriesListMenuTrigger.menuClosed.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: (_) => {
        this.countryMenuIsOpened = false;
      },
    });
    this._countriesListMenuTrigger.menuOpened.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: (_) => {
        this.countryMenuIsOpened = true;
      },
    });
  }

  private _doGetUserCountry(): void {
    this.userCountry = getSupportedPhoneCountriesUseCase(this.removeUncoveredCountries).find(
      (country: any) => country.isoCode3 === this.userCountryCode,
    );
    this._commonPatchFormValue('country', this.userCountry, false);
    this._doSetPhoneNumberValidators();
  }

  private _doSetPhoneNumberValidators(): void {
    this.metaPhoneNumberFormGroup
      .get('phoneNumber')!
      .setValidators([
        this.regexValidator(new RegExp(this.userCountry.phoneRegex), { invalidPhoneNumber: true }),
      ]);
  }

  public get phoneNumber(): AbstractControl {
    return this.metaPhoneNumberFormGroup.get('phoneNumber')!;
  }

  public regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  private _commonPatchFormValue(control: string, value: any, emitEvent: boolean): void {
    this.metaPhoneNumberFormGroup.patchValue({ [control]: value }, { emitEvent });
  }

  private _initializeMetaPhoneNumberFormGroup(): void {
    this.metaPhoneNumberFormGroup = new UntypedFormGroup({
      country: new UntypedFormControl('', [Validators.required]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
    });
    let initialPhoneNumberValue = 0;
    if (typeof this.userPhoneNumber === 'string') {
      initialPhoneNumberValue = parseInt(this.userPhoneNumber.split(' ').join(''));
    } else {
      initialPhoneNumberValue = Number(this.userPhoneNumber);
    }
    this._commonPatchFormValue('phoneNumber', initialPhoneNumberValue, false);
    this._listenForFormChanges();
  }

  private _listenForFormChanges(): void {
    this.metaPhoneNumberFormGroup.valueChanges
      .pipe(takeUntil(this._onDestroy$))
      .subscribe({ next: (newValue) => this.result$.emit(newValue) });
  }
}
