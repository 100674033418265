<div class="notification-wrapper {{ config.status }} body1--regular">
  <ng-container *ngTemplateOutlet="renderNotificationTemplate(config.status)"></ng-container>
</div>

<ng-template #successResponseTemplate>
  <div *ngIf="config.iconMeta.position === 'before'">
    <ng-template [ngTemplateOutlet]="notificationIconTemplate"></ng-template>
  </div>
  <ng-template [ngTemplateOutlet]="notificationTextTemplate"></ng-template>
  <div *ngIf="config.iconMeta.position === 'after'">
    <ng-template [ngTemplateOutlet]="notificationIconTemplate"></ng-template>
  </div>
</ng-template>

<ng-template #errorResponseTemplate>
  <div *ngIf="config.iconMeta.position === 'before'">
    <ng-template [ngTemplateOutlet]="notificationIconTemplate"></ng-template>
  </div>
  <ng-template [ngTemplateOutlet]="notificationTextTemplate"></ng-template>
  <div *ngIf="config.iconMeta.position === 'after'">
    <ng-template [ngTemplateOutlet]="notificationIconTemplate"></ng-template>
  </div>
</ng-template>

<!-- other notifications go here -->

<!-- shared notification UI pieces -->
<ng-template #notificationTextTemplate>
  {{ config.msg }}
</ng-template>

<ng-template #notificationIconTemplate>
  <img loading="lazy" src="{{ config.iconMeta.icon }}" alt="{{ config.status }}" />
</ng-template>
