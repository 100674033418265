<form
  [formGroup]="metaPhoneNumberFormGroup"
  class="meta-phone-number-field"
  #phoneNumberWrapperElement
>
  <div class="meta-phone-number-field__input">
    <input
      (blur)="onInputBlurEvent()"
      placeholder="{{ countryPhoneHint }}"
      type="number"
      formControlName="phoneNumber"
      data-test-id="login-form-phone-number"
      class="body2--medium"
    />
  </div>
  <div class="meta-phone-number-field__divider"></div>
  <div
    [ngClass]="{ 'meta-phone-number-field__code--disabled': prefixDisabled }"
    [matMenuTriggerFor]="countriesListMenu"
    #countriesListMenuTrigger="matMenuTrigger"
    class="meta-phone-number-field__code body1--medium"
    data-test-id="login-form-phone-country-code"
  >
    <div class="meta-phone-number-field__code__countries-list-toggler" *ngIf="!prefixDisabled">
      <ng-container
        *ngTemplateOutlet="countryMenuIsOpened ? menuCloseIconTemplate : menuOpenIconTemplate"
      ></ng-container>
      <ng-template #menuOpenIconTemplate>
        <mat-icon>expand_more</mat-icon>
      </ng-template>
      <ng-template #menuCloseIconTemplate>
        <mat-icon>expand_less</mat-icon>
      </ng-template>
    </div>
    <ng-template
      [ngTemplateOutlet]="countryFlagAndCodeTemplate"
      [ngTemplateOutletContext]="{ country: userCountry }"
    ></ng-template>
  </div>
</form>

<div class="text-error caption1--regular" *ngIf="phoneNumber.errors?.invalidPhoneNumber">
  رقم الهاتف غير صحيح
</div>

<mat-menu
  [class]="'meta-phone-number-countries-menu'"
  [hasBackdrop]="false"
  #countriesListMenu="matMenu"
>
  <div class="mat-menu__phonenumbers-list">
    <div
      *ngFor="let country of visibleCountries"
      class="mat-menu__phonenumbers-list__phone-item"
      (click)="onCountryChange(country)"
      data-test-id="country-code-option"
    >
      <ng-template
        [ngTemplateOutlet]="countryFlagAndCodeTemplate"
        [ngTemplateOutletContext]="{ country: country, scope: 'on-overlay' }"
      ></ng-template>
    </div>
  </div>
</mat-menu>

<ng-template #countryFlagAndCodeTemplate let-country="country" let-scope="scope">
  <div class="country-flag-and-code {{ scope }}">
    <div class="country-flag-and-code__code body1--medium">+{{ country.phoneNumPrefix }}</div>
    <div class="country-flag-and-code__country-flag">
      <img loading="lazy" src="{{ country.flagUrl }}" alt="{{ country.englishName }}" />
    </div>
  </div>
</ng-template>
